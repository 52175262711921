const Side = ({reversed, image, title, description}) => {
    return(
        <div className={`side-holder ${reversed && 'reversed'}`}>
            <div className={`side-first ${reversed ? 'min-[600px]:align-end' : 'align-start'}`}>
                <h2 className="side-h2 mb-16">{title}</h2>
                <p className="side-p mb-24 mt-24">{description}</p>
            </div>

            <div className={`side-second ${reversed ? 'flex-start' : 'flex-end'}`}>
                <img src ={image} className="side-image" alt={title}/>
            </div>
        </div>
    )
}

export { Side }