import { faEyeSlash, faGraduationCap, faSwatchbook, faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import phone1 from "./assets/phone1.jpeg";
import phone2 from "./assets/phone2.jpeg";
import phone3 from "./assets/phone3.jpeg";
// import phone4 from "./assets/phone4.jpeg";

export const readmore = [
    `Introducing VIIO, the revolutionary app that brings text to life by reading it out loud! With VIIO, you can transform any written content 
    into an immersive audio experience, allowing you to absorb information effortlessly, whether you're on the go or simply prefer listening 
    over reading.`,
    `VIIO offers a seamless and intuitive interface, making it incredibly easy to use. Simply copy text, take pictures of documents, or upload PDF files, 
     and VIIO will instantly convert them into crystal-clear audio. Say goodbye to tedious reading sessions and let VIIO do the work for you.`,
    `Ideal for students, professionals, or anyone seeking a convenient way to understand and retain information, VIIO offers a range of powerful features. 
     Customize the reading speed and voice to suit your preferences, and take advantage of advanced options like highlighting important sections or 
     adjusting the voice pitch for enhanced clarity.`,
     `VIIO isn't just about convenience; it's also a versatile tool for accessibility. Empower visually impaired individuals or those with reading 
      difficulties to access a world of knowledge with ease. VIIO's text-to-speech technology is designed to provide a smooth and natural reading 
      experience, ensuring every word is delivered with precision and accuracy.`,
    `Download VIIO now and experience the future of text consumption. Embrace the power of audio and unlock a new way to understand and learn. Let VIIO 
     be your trusted companion in transforming written content into an immersive audio journey, making knowledge accessible to all.`
]

export const appPointerData = [
  {
    title: "Intuitive interface",
    description: "Nulla accumsan consequat magna, vel faucibus tellus vulputate ac. Aliquam consectetur feugiat diam, id porta ipsum semper sed", 
    icon: faSwatchbook
  },
  {
    title: "Ideal for students",
    description: "Nulla accumsan consequat magna, vel faucibus tellus vulputate ac. Aliquam consectetur feugiat diam, id porta ipsum semper sed", 
    icon: faGraduationCap
  },
  {
    title: "Accessible",
    description: "Nulla accumsan consequat magna, vel faucibus tellus vulputate ac. Aliquam consectetur feugiat diam, id porta ipsum semper sed", 
    icon: faEyeSlash
  },
  {
    title: "Customize your app",
    description: "Nulla accumsan consequat magna, vel faucibus tellus vulputate ac. Aliquam consectetur feugiat diam, id porta ipsum semper sed", 
    icon: faPenToSquare
  }
]

export const stepsData = [
  "Download App from the respective store on your phone",
  "Upload text file from your phone to the app",
  "Sit back and listen at your own convienience"
]

export const sideData = [
  {
    title: "Upload content",
    description: "Uploading your file to our app is super convenient, with multiple options tailored to your need. You can take a picture of the document, upload picture of your document, upload PDF document, past text and paste weblink ",
    image: phone1
  },
  {
    title: "Take picture of content",
    description: "Capture a clear and focused image of the content you wish to preserve or share. Ensure that the entire area of interest is within the frame, and adjust the lighting and angle for optimal clarity. ",
    image: phone2,
  },
  {
    title: "Listen to content",
    description: "Engage with audio-based material by listening to the text of the document uploaded. This mode of consumption allows for multitasking and on-the-go access to information, entertainment, or education.",
    image: phone3
  },
  // {
  //   title: "Save content for later",
  //   description: "Easily save content to access it at a more convenient time. This feature allows you to store your uploads, ensuring you can revisit it whenever you need without losing track of valuable information.",
  //   image: phone4,
  // }
]