import { sideData } from "../data"
import { Side } from "./Side"
import girlcolor from '../assets/girlwithtextcolor.png'
import girl from '../assets/girl with text box.png'
import screen from '../assets/stareatscreen.png'
import asian from '../assets/AsianwithPhone.png'
import viio from '../assets/viioimg.png'


const SideHolder = () => {
    return(
        <div>
            <div className="mini-width mx-16">
                {
                    sideData.map((side, index) => {
                        return <Side 
                            key={side.title}
                            reversed={index % 2 === 1} 
                            title={side.title} 
                            description={side.description}
                            image={side.image} />
                    })
                }
            </div>
            {/* <div className="landing-hero mini-width">
                <h2 className='color-white landing-text mb-24 mt-24 side-h2'>Best text to speech app for android</h2>
                <p className='color-white landing-text mb-24 mt-24'>Looking for the best text to speech app to turn your Android device into a reading machine?  
                Whether you're a busy commuter or someone who enjoys audiobooks, a TTS app can be a game-changer.  
                These apps can read web articles, emails, documents, and even ebooks out loud, freeing up your eyes and letting you multitask.  
                With a variety of options available, there's a perfect text to speech app for everyone.  Explore features like natural-sounding voices, multiple languages, 
                and customization options to find your ideal fit!</p>

                <img src={girlcolor} alt='' className="picture-girl"/>

                <h2 className='color-white landing-text mb-24 mt-24 side-h2'>Best text to speech app for iOS</h2>
                <p className='color-white landing-text mb-24 mt-24'>Tired of straining your eyes reading on your iPhone? There's a smarter way! 
                Text-to-speech apps for iOS can convert written content into natural-sounding narration, 
                letting you listen to articles, emails, and even ebooks while you multitask. But with so many options, choosing the best one can be overwhelming.
                </p>
                <p className='color-white landing-text mb-24 mt-24'>That's where VIIO comes in. VIIO is a powerful text-to-speech app 
                for iPhone that offers exceptional voice quality and 
                a wide range of features to enhance your listening experience.
                Discover VIIO today and unlock a new way to consume content on the go!</p>
                <h2 className='color-white landing-text mb-24 mt-24 side-h2'>PDF to Audio</h2>
                <p className='color-white landing-text mb-24 mt-24'>Transform your PDF documents into audio files.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>Use Case:</strong> Ideal for listening to e-books, reports, research papers, 
                manuals, and any other PDF documents on the go.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>How It Works:</strong> Upload your PDF file, choose your preferred voice and language, 
                and let VIIO convert the text into a high-quality audio file. 
                You can download the audio and listen to it on any device.</p>

                <img src={screen} alt='VIIO logo' className="picture-screen"/>

                <h2 className='color-white landing-text mb-24 mt-24 side-h2'>Text Documents to Audio</h2>
                <p className='color-white landing-text mb-24 mt-24'>Convert plain text files into engaging audio content.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>Use Case:</strong> Perfect for turning notes, articles, essays, 
                and any other plain text documents into audio.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>How It Works:</strong> Simply upload your text document (TXT, DOC, DOCX), 
                select your voice settings, and get an audio file ready for download. 
                Whether it’s for studying, work, or leisure, listening has never been easier.</p>

                <br/><br /><img src={viio} alt='VIIO logo'  className="picture"/><br/><br/>

                <h2 className='color-white landing-text mb-24 mt-24 side-h2'>Web Articles to Audio</h2>
                <p className='color-white landing-text mb-24 mt-24'>Listen to your favorite web content.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>Use Case:</strong> Great for busy individuals who want to keep up with articles, 
                blog posts, and news without having to read them.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>How It Works:</strong> Provide the URL of the web page, and Viio will extract the 
                text content and convert it into an audio file. Choose your preferred narration style and enjoy listening while multitasking.</p>


        
                <h2 className='color-white landing-text mb-24 mt-24 side-h2'>Scanned Documents to Audio</h2>
                <p className='color-white landing-text mb-24 mt-24'>Listen to scanned documents with ease.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>Use Case:</strong> Useful for converting scanned PDFs or images with text into audio, 
                such as scanned books, articles, or handwritten notes.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>How It Works:</strong> Upload your scanned document, and VIIO will use OCR (Optical Character Recognition) 
                technology to extract the text. Choose your voice settings, and convert the text into audio.</p>



                <h2 className='color-white landing-text mb-24 mt-24 side-h2'>Custom Text to Audio</h2>
                <p className='color-white landing-text mb-24 mt-24'>Create audio from any custom text input.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>Use Case:</strong> Suitable for creating audio content from any text you write or copy-paste into VIIO, 
                such as scripts, stories, or personal notes.</p>
                <p className='color-white landing-text mb-24 mt-24'><strong>How It Works:</strong> Enter or paste your text into VIIO’s interface, select your voice and language settings, 
                and convert it to an audio file. Perfect for creating personalized audio content.</p>
                     
            </div> */}
            <div className="landing-hero mini-width">
                <p className='color-white landing-text mb-24 mt-24'><strong>Get Started Now!</strong></p>
                <p className='color-white landing-text mb-24 mt-24'>Transform your reading experience. Upload your first file and listen on the go.</p>
            </div>
        </div>
    )
}


export {SideHolder}