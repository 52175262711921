import React, { useEffect } from 'react';
import logo from '../assets/logo.jpeg'
// import { Button } from './Button'
import { StoreButton } from './StoreButton'
import homepage from '../assets/homepage.jpeg'


const Hero = () => {
    useEffect(() => {
        const lines = document.querySelectorAll('.landing-hero-text div, .landing-hero-text h1, .landing-hero-text p');
        lines.forEach((line, index) => {
            setTimeout(() => {
                line.classList.add('visible');
            }, index * 250); // Adjust the delay as needed
        });

    // Add visible class to buttons together
        const buttons = document.querySelectorAll('.store-holder .store-btn');
        setTimeout(() => {
            buttons.forEach(button => button.classList.add('visible'));
        }, lines.length * 150); // Adjust the delay as needed
    }, []);

    return(
        <div className="landing-hero min-width">
            <div className='landing-hero-text'>
                <img src={logo} alt='VIIO logo' className='hero-image' />

                <h1 className='landing-text-header'>
                Best text to speech app
                </h1>

                <div className='color-white landing-text text-center mb-24 mt-24 px-6'>
                <p>Welcome to VIIO, your go-to solution for converting written content into</p>
                <p>high-quality audio. Whether it's PDFs, text documents, or any other format,</p>
                <p>we turn your words into a seamless audio experience.</p>
                </div>

                <p className='color-white landing-text text-center mb-24 mt-24'>
                Perfect for those who prefer listening over reading, multitaskers, or anyone
                <div>who wants to make their written content more accessible.</div>
                </p>

                {/* <Button text="Download App" className='mt-16' /> */}
                <div className='store-holder flex mt-16'>
                    <StoreButton type="apple" className=" mr-16" />

                    <StoreButton type="google"  />
                </div>
            </div>
            <div className='viio-reasons px-16'>
                <div className='viio-reasons-img'>
                    <img src={homepage} alt='HomePage Image' className='homePageImage' />
                </div>

                <div className='viio-reasons-text'>
                    <h2 className='color-white landing-text mb-24 mt-24 side-h2'>Why Choose VIIO?</h2>
                    <ul className='color-white landing-text mb-24 mt-24'>
                        <li><strong>Versatile Formats:</strong> Convert PDFs, text files, web pages, and more into audio.</li>
                        <li><strong>High-Quality Audio:</strong> Enjoy clear and natural-sounding voice narration.</li>
                        <li><strong>Easy to Use:</strong> Simple upload process and instant conversion.</li>
                        <li><strong>Customizable Voice Options:</strong> Choose from a range of voices and languages.</li>
                        <li><strong>Accessible Everywhere:</strong> Listen on your desktop, mobile, or any device.</li>
                    </ul>
                </div>
            </div>

            <h4 className='color-white landing-text mb-24 mt-24 side-h4'>How It Works</h4>
            <ol className='color-white landing-text mb-24 mt-24'>
                <li><strong>Upload Your File:</strong> Select the file you want to convert.</li>
                <li><strong>Choose Voice Settings:</strong> Pick your preferred voice and language.</li>
                <li><strong>Convert and Download:</strong> Click convert, and your audio file will be ready in minutes.</li>
            </ol>
        </div>
    )
}


export {Hero}