import React from 'react';
import { Helmet } from 'react-helmet';

const Schema = () => {
  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "VIIO",
    "url": "https://www.viio.app/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "{search_term_string}",
      "query-input": "required name=search_term_string"
    }
  
  };

  const schemaData2 = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://blog.viio.app/"
    },
    "headline": "NO#1 Text to Speech App",
    "image": "https://www.viio.app/static/media/homepage.9dbccc8f1698a7bcf2f5.jpeg",  
    "author": {
      "@type": "Person",
      "name": "Viio",
      "url": "https://www.viio.app/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "",
      "logo": {
      "@type": "ImageObject",
      "url": "https://www.viio.app/"
      }
    },
    "datePublished": ""
  };
  
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(schemaData2)}
      </script>
    </Helmet>
  );
};

export {Schema};
