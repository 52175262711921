import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faGooglePlay } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons'


const StoreButton = ({className, type}) => {
    return(
        <a className={`store-btn btn ${className}`} href={type==="google" ? 
            "https://play.google.com/store/apps/details?id=com.viio.viioai&hl=en&pli=1" : "https://apps.apple.com/us/app/viio-text-reader/id1570401477"} 
            target='_blank'  rel="noreferrer">

            <FontAwesomeIcon className='store-icon' icon={ type==="google" ? faGooglePlay : faApple} />

            <div className='flex-column align-start ml-8'>
                <p className='mini'>{type === "google" ? "GET IT ON" : "Download on the"}</p>
                <p className='store-bold'>{type === "google" ? "Google Play" : "App Store"}</p>
            </div>
        </a>
    )
}


export {StoreButton}

