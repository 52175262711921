// import logo from '../assets/logo.jpeg'
// import { fontAwesome } from 'fontawesome'

import { faRightLong } from '@fortawesome/free-solid-svg-icons'
import { Button } from './Button'

const Nav = () => {
    return(
        <div className="color-white landing-nav">
            {/* <img src={logo} alt="logo" className='logo' /> */}

            <Button icon={faRightLong} text="Visit our Blog" href="https://blog.viio.app/" />

            {/* <a className='btn'>
                <span className='mr-2'>Get App</span>
               
                <FontAwesomeIcon icon={faRightLong} />
            </a> */}
        </div>
    )
}


export { Nav }