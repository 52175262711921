// import logo from './logo.svg';
import './App.css';
import './style/util.css'
import { Hero } from './components/Hero';
import { Nav } from './components/Nav';
import { Helmet } from 'react-helmet';
import { Schema } from './components/Schema';

import { SideHolder } from './components/SideHolder';

function App() {
  return (
    <div className="App">
      <Schema />
      <Helmet>
        <title>VIIO best text to speech app: Enhance Your Reading Experience</title>
        <meta name="title" content="Free Text to Speech App: #1 Conversational TTS Voices" />
        <meta name="description" content="Discover the best text to speech app with VIIO. Convert text, PDFs, and web articles into interactive audiobooks for a seamless listening experience." />
        <meta name="google-site-verification" content="ey8yq3CrTFqKt0Jrf2-m0tXGRUC8pGoMwfAuQQ_yJmQ" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-RT0ZX108YP"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-RT0ZX108YP');
          `}
        </script>
      </Helmet>
      <Nav />

      <Hero />

      <SideHolder />

      {/* <Side image={phone1} text={"Upload content with any of these options"} />

      <Side image={phone2} text={"Take picture of content"} reversed />

      <Side image={phone3} text={"Listen to content"} />

      <Side image={phone4} text={"Save content for later"} reversed /> */}

      {/* <HowToUse /> */}

      {/* <Intro /> */}

      {/* <Join /> */}

      {/* <ReadMore /> */}

      {/* <Steps /> */}
      
      {/* <Footer /> */}
    </div>
  );
}

export default App;
