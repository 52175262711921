// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = ({icon, text, className, href}) => {
    return(
        <a className={`btn ${className}`} href={href} rel="noreferrer">
            <span className='mr-2'>{text}</span>

            {/* {
                icon && <FontAwesomeIcon icon={icon} />
            } */}
            
        </a>
    )
}

export {Button}